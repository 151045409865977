import { useTranslation } from "react-i18next";
import PageTitle from "../../../components/page-title";
import PriceData from "../../../data/price-data.json";

const PageTitleContainer = () => {
    const { t, i18n } = useTranslation();
    const currenLanguage = i18n.language;
    return (
        <div className="page-title-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12" data-aos="fade-up">
                        <PageTitle
                            classOption="page-title-content content-style2 text-center"
                            subTitle={PriceData.title.subTitle[currenLanguage]}
                            title={PriceData.title[currenLanguage]}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageTitleContainer;
