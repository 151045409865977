import { useTranslation } from "react-i18next";
import PageTitle from "../../../components/page-title";

const PageTitleContainer = () => {
    const { t } = useTranslation();
    return (
        <div className="page-title-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12" data-aos="fade-up">
                        <PageTitle
                            classOption="page-title-content content-style2 text-center"
                            subTitle={t("about.title")}
                            title={t("about.desc")}
                        />
                    </div>
                </div>
            </div>
            <div className="thumb" data-aos="fade-up" data-aos-delay="300">
                <img
                    className="w-100"
                    src={process.env.PUBLIC_URL + "./img/about/my_photo.jpg"}
                    alt="My portrait"
                />
            </div>
        </div>
    );
};

export default PageTitleContainer;
