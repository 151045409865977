import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const PortfolioDetailsContainer = ({ data }) => {
    const { t, i18n } = useTranslation();
    const currenLanguage = i18n.language;
    const cate = data.categories.map((value, idx) => {
        return (
            <span className="d-inline" key={idx}>
                {t(value)}
                {idx !== data.categories.length - 1 && " , "}
            </span>
        );
    });
    const domain = process.env.PUBLIC_URL + "/";
    const prevLink = data.pageUrl.hasOwnProperty("prev")
        ? domain + "portfolio-details/" + data.pageUrl.prev
        : domain;
    const prevLinkTitle = data.pageUrl.hasOwnProperty("prev")
        ? "portfolio.navigation.previous"
        : "portfolio.navigation.home";
    const nextLink = data.pageUrl.hasOwnProperty("next")
        ? domain + "portfolio-details/" + data.pageUrl.next
        : false;

    return (
        <div className="portfolio-area portfolio-single">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="inner-content">
                            <div className="content" data-aos="fade-up">
                                <p className="category">{cate}</p>
                                <h3 className="title">
                                    {data[currenLanguage].title}
                                </h3>
                            </div>
                            <div className="portfolio-info">
                                <div className="row">
                                    <div
                                        className="col-sm-6 col-md-4 col-lg-4"
                                        data-aos="fade-up"
                                    >
                                        <div className="info-item">
                                            <span>
                                                {t("portfolio.details.date")}
                                            </span>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: data[currenLanguage]
                                                        .date,
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="col-sm-6 col-md-4 col-lg-4"
                                        data-aos="fade-up"
                                        data-aos-delay="300"
                                    >
                                        <div className="info-item">
                                            <span>
                                                {t("portfolio.details.team")}
                                            </span>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: data[currenLanguage]
                                                        .team,
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="col-sm-6 col-md-4 col-lg-4"
                                        data-aos="fade-up"
                                        data-aos-delay="600"
                                    >
                                        <div className="info-item style-two">
                                            <span>
                                                {t(
                                                    "portfolio.details.services"
                                                )}
                                            </span>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: data[currenLanguage]
                                                        .services,
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="portfolio-content"
                                data-aos="fade-up"
                            >
                                {data[currenLanguage].body.map((value, i) => {
                                    return (
                                        <div
                                            key={i}
                                            className="content-wrap"
                                            dangerouslySetInnerHTML={{
                                                __html: value,
                                            }}
                                        />
                                    );
                                })}
                                <Link
                                    to={
                                        process.env.PUBLIC_URL +
                                        data.pageUrl.link
                                    }
                                >
                                    {data.pageUrl.text}
                                </Link>
                            </div>
                            <div
                                className="thumb section-padding-bottom"
                                data-aos="fade-up"
                                data-aos-delay="300"
                            >
                                <img
                                    className="w-100"
                                    src={`${process.env.PUBLIC_URL}/${data.gallery.imageOne}`}
                                    alt="Alexis"
                                />
                            </div>
                            <div className="row">
                                <div
                                    className="col-lg-8 m-auto"
                                    data-aos="fade-up"
                                    data-aos-delay="300"
                                >
                                    <div className="thumb section-padding-bottom">
                                        <img
                                            className="w-100"
                                            src={`${process.env.PUBLIC_URL}/${data.gallery.imageTwo}`}
                                            alt="Alexis"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-lg-12" data-aos="fade-up">
                        <div className="thumb section-padding-bottom">
                            <img
                                className="w-100"
                                src={`${process.env.PUBLIC_URL}/${data.gallery.imageThree}`}
                                alt="Alexis"
                            />
                        </div>
                    </div>
                </div>
                <div className="row thumb style-two">
                    <div
                        className="col-md-6 col-lg-5 pl-sm-15 pl-0"
                        data-aos="fade-up"
                        data-aos-delay="300"
                    >
                        <img
                            className="mb-xs-30 mb-sm-60"
                            src={`${process.env.PUBLIC_URL}/${data.gallery.imageFour}`}
                            alt="Alexis"
                        />
                    </div>
                    <div
                        className="col-md-6 col-lg-7 text-end pr-sm-15 pr-0"
                        data-aos="fade-up"
                        data-aos-delay="600"
                    >
                        <img
                            className="sm-pl-0 pl-md-50"
                            src={`${process.env.PUBLIC_URL}/${data.gallery.imageFive}`}
                            alt="Alexis"
                        />
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div
                        className="col-lg-12"
                        data-aos="fade-up"
                        data-aos-delay="600"
                    >
                        <div className="portfolio-navigation">
                            <div className="prev">
                                <Link to={prevLink}>
                                    <i className="arrow_carrot-left"></i>{" "}
                                    {t(prevLinkTitle)}
                                </Link>
                            </div>
                            {nextLink ? (
                                <div className="next">
                                    <Link to={nextLink}>
                                        {t("portfolio.navigation.next")}
                                        <i className="arrow_carrot-right"></i>
                                    </Link>
                                </div>
                            ) : (
                                <div className="next"></div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

PortfolioDetailsContainer.propTypes = {
    data: PropTypes.object,
};

export default PortfolioDetailsContainer;
