import PriceData from "../../../data/price-data.json";
import { useTranslation } from "react-i18next";

const AdditionalSection = () => {
    const { t, i18n } = useTranslation();
    const currenLanguage = i18n.language;
    const Data = PriceData.additional;
    return (
        <div className="post-details-body">
            <div className="content row">
                <div
                    className="col-sm-12 col-lg-12 col-md-12"
                    data-aos="fade-up"
                >
                    <h2 className="title">{Data.title[currenLanguage]}</h2>
                    <p
                        className="text-block"
                        dangerouslySetInnerHTML={{
                            __html: Data.subTitle[currenLanguage],
                        }}
                    />
                    {Data.text[currenLanguage] &&
                        Data.text[currenLanguage].map((value, key) => {
                            return (
                                <p
                                    className="desc"
                                    key={key}
                                    dangerouslySetInnerHTML={{
                                        __html: value.strong + value.usual,
                                    }}
                                />
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

export default AdditionalSection;
