import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const LanguageSelector = () => {
    const { t, i18n } = useTranslation();

    const changeLanguage = (event) => {
        i18n.changeLanguage(event.target.value);
    };

    const currenLanguage = i18n.language;

    return (
        <div
            onChange={changeLanguage}
            className="language"
            role="radiogroup"
            aria-labelledby="language-switcher1"
        >
            <div className="language__container--left language__container--en">
                <input
                    value="en"
                    className="language__control"
                    type="radio"
                    id="language1-1"
                    name="language-switch1"
                    defaultChecked={currenLanguage === "en" ? true : false}
                />
                <label className="language__label" htmlFor="language1-1">
                    EN
                </label>
            </div>
            <div className="language__container--right language__container--ua">
                <input
                    value="ua"
                    className="language__control"
                    type="radio"
                    id="language1-2"
                    name="language-switch1"
                    defaultChecked={currenLanguage === "ua" ? true : false}
                />
                <label className="language__label" htmlFor="language1-2">
                    UA
                </label>
            </div>
        </div>
    );
};

LanguageSelector.propTypes = {
    data: PropTypes.object,
};

export default LanguageSelector;
