import PropTypes from "prop-types";
import React from "react";
import {
    ReactCompareSlider,
    // eslint-disable-next-line prettier/prettier
    ReactCompareSliderImage
} from "react-compare-slider";

const RetouchItem = ({ data }) => {
    return (
        <div className="single-portfolio">
            {"compare" in data && data?.compare && (
                <div className="retouch-thumbnail w-100">
                    <ReactCompareSlider
                        itemOne={
                            <ReactCompareSliderImage
                                src={process.env.PUBLIC_URL + data.homeImage}
                                alt="Image one"
                            />
                        }
                        itemTwo={
                            <ReactCompareSliderImage
                                src={process.env.PUBLIC_URL + data.rawImage}
                                alt="Image two"
                            />
                        }
                    />
                </div>
            )}
        </div>
    );
};

RetouchItem.propTypes = {
    data: PropTypes.object,
};

export default RetouchItem;
