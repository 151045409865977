import PropTypes from "prop-types";
import React from "react";
import Qute from "../../../components/qute/index";

const QuteContainer = ({ data }) => {
    return (
        <div className="home-slider-area slider-photographer">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-lg-11 col-xl-10 m-auto">
                        <Qute data={data} />
                    </div>
                </div>
            </div>
        </div>
    );
};

QuteContainer.propTypes = {
    data: PropTypes.string,
};

export default QuteContainer;
