import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LightgalleryProvider } from "react-lightgallery";
import PortfolioFilter from "../../../components/portfolio/portfolio-filter";
import PortfolioItem from "../../../components/portfolio/portfolio-item";
import PortfolioData from "../../../data/portfolio.json";
import PopupVideo from "../../../components/popup-video";
import useMasonry from "../../../hooks/use-masonry";
import { slugify } from "../../../utils";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import PropTypes from "prop-types";

const ItemTypes = {
    IMAGE: "image",
};

const DraggablePortfolioItem = ({
    portfolio,
    index,
    moveImage,
    isDraggable,
}) => {
    const ref = React.useRef(null);
    const [, drop] = useDrop({
        accept: ItemTypes.IMAGE,
        hover(item) {
            if (item.index !== index) {
                moveImage(item.index, index);
                item.index = index;
            }
        },
    });
    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.IMAGE,
        item: { id: portfolio.id, index },
        canDrag: isDraggable,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    drag(drop(ref));

    return (
        <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }}>
            {portfolio.type === "image" ? (
                <PortfolioItem portfolio={portfolio} />
            ) : (
                <PopupVideo data={portfolio} />
            )}
        </div>
    );
};

DraggablePortfolioItem.propTypes = {
    portfolio: PropTypes.shape({
        id: PropTypes.number.isRequired,
        type: PropTypes.string.isRequired,
        homeImage: PropTypes.string,
        categories: PropTypes.arrayOf(PropTypes.string),
        en: PropTypes.shape({
            model: PropTypes.string,
            team: PropTypes.string,
            date: PropTypes.string,
        }),
    }).isRequired,
    index: PropTypes.number.isRequired,
    moveImage: PropTypes.func.isRequired,
    isDraggable: PropTypes.bool.isRequired,
};

const PortfolioContainer = () => {
    // Isotope Categories list JS
    const { t, i18n } = useTranslation();
    const currenLanguage = i18n.language;
    const [items, setItems] = useState(PortfolioData);
    const isDraggable = window.location.hostname === "localhost";

    const { categories } = useMasonry(
        items,
        ".portfolio-list",
        ".masonry-grid",
        ".messonry-button",
        ".messonry-button button"
    );

    useEffect(() => {
        if (isDraggable) {
            console.clear();
            console.log(JSON.stringify(items));
        }
    }, [items, isDraggable]);

    const moveImage = (dragIndex, hoverIndex) => {
        const draggedItem = items[dragIndex];
        const newItems = [...items];
        newItems.splice(dragIndex, 1);
        newItems.splice(hoverIndex, 0, draggedItem);
        setItems(newItems);
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="portfolio-area portfolio-default-area">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="messonry-button text-center mb-50">
                                <PortfolioFilter categories={categories} />
                            </div>
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 portfolio-list mb-n30">
                        <div className="col resizer"></div>
                        <LightgalleryProvider
                            lightgallerySettings={{ preload: 1 }}
                        >
                            {items.map((portfolio, index) => (
                                <div
                                    key={portfolio.id}
                                    className={`col masonry-grid mb-30 ${portfolio.categories
                                        .map((cat) => slugify(cat))
                                        .join(" ")}`}
                                >
                                    <DraggablePortfolioItem
                                        portfolio={portfolio}
                                        index={index}
                                        moveImage={moveImage}
                                        isDraggable={isDraggable}
                                    />
                                </div>
                            ))}
                        </LightgalleryProvider>
                    </div>
                </div>
            </div>
        </DndProvider>
    );
};

export default PortfolioContainer;
