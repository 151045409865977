import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const ContactForm = () => {
    const { register, errors, formState } = useForm({
        mode: "onBlur",
        defaultValues: {
            message: "",
        },
    });
    const { isDirty, isValid } = formState;
    const { t } = useTranslation();
    return (
        <Fragment>
            <form
                className="contact-form-wrapper"
                action="https://getform.io/f/24983443-a47d-4187-8e13-d28c02ce5b85"
                method="POST"
            >
                <div className="row">
                    <div
                        className="col-md-4"
                        data-aos="fade-up"
                        data-aos-delay="300"
                    >
                        <div className="form-group">
                            <input
                                className="form-control"
                                type="text"
                                name="name"
                                placeholder={t("contact.form.name")}
                                ref={register({
                                    required: t(
                                        "contact.form.validation.require.name"
                                    ),
                                })}
                            />
                            {errors.name && <p>{errors.name.message}</p>}
                        </div>
                    </div>
                    <div
                        className="col-md-4"
                        data-aos="fade-up"
                        data-aos-delay="600"
                    >
                        <div className="form-group">
                            <input
                                className="form-control"
                                type="email"
                                name="email"
                                placeholder={t("contact.form.email")}
                                ref={register({
                                    required: t(
                                        "contact.form.validation.require.email"
                                    ),
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: t(
                                            "contact.form.validation.parse.email"
                                        ),
                                    },
                                })}
                            />
                            {errors.email && <p>{errors.email.message}</p>}
                        </div>
                    </div>
                    <div
                        className="col-md-4"
                        data-aos="fade-up"
                        data-aos-delay="900"
                    >
                        <div className="form-group">
                            <input
                                className="form-control"
                                type="text"
                                name="subject"
                                placeholder={t("contact.form.subject")}
                                ref={register({
                                    required: t(
                                        "contact.form.validation.require.subject"
                                    ),
                                })}
                            />
                            {errors.subject && <p>{errors.subject.message}</p>}
                        </div>
                    </div>
                    <div className="col-md-12" data-aos="fade-up">
                        <div className="form-group mb-0">
                            <textarea
                                name="message"
                                rows="5"
                                placeholder={t("contact.form.message")}
                            ></textarea>
                            {errors.message && <p>{errors.message.message}</p>}
                        </div>
                    </div>
                    <div
                        className="col-md-12 text-center"
                        data-aos="fade-up"
                        data-aos-delay="300"
                    >
                        <div className="form-group mb-0">
                            <button
                                disabled={!isDirty || !isValid}
                                className="btn-submit"
                                type="submit"
                            >
                                {t("contact.form.submit")}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </Fragment>
    );
};

export default ContactForm;
