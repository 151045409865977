import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { slugify } from "../../../utils";

const PortfolioFilter = ({ categories }) => {
    const { t, i18n } = useTranslation();
    const currenLanguage = i18n.language;
    const [selectedCategory, setSelectedCategory] = useState(
        "portfolio.category.description.catalog"
    );

    const handleCategoryClick = (category) => {
        let categoryName = category.split(".");

        setSelectedCategory(
            "portfolio.category.description." + categoryName[2]
        );
    };

    return (
        <div className="messonry-button text-center mb-50">
            {categories?.map((cat, idx) => (
                <button
                    key={idx}
                    data-filter={`.${slugify(cat)}`}
                    onClick={() => handleCategoryClick(cat)}
                    className={
                        cat === "portfolio.category.catalog" ? "is-checked" : ""
                    }
                >
                    <span className="filter-text">{t(cat)}</span>
                </button>
            ))}
            <button data-filter="*">
                <span className="filter-text">
                    {t("portfolio.category.all")}
                </span>
            </button>
            <div className="text-center mb-50">{t(selectedCategory)}</div>
        </div>
    );
};

PortfolioFilter.propTypes = {
    categories: PropTypes.array,
};

export default PortfolioFilter;
