import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { LightgalleryItem } from "react-lightgallery";

const PortfolioItem = ({ portfolio }) => {
    const { t, i18n } = useTranslation();
    const currenLanguage = i18n.language;
    return (
        <div className="single-portfolio">
            <LightgalleryItem
                group="any"
                src={process.env.PUBLIC_URL + portfolio.homeImage}
            >
                <div className="thumbnail">
                    <div className="overlay">
                        <img
                            src={process.env.PUBLIC_URL + portfolio.homeImage}
                            alt="portfolio"
                        />
                    </div>
                </div>
            </LightgalleryItem>
            <div className="content">
                <p className="desc">{portfolio[currenLanguage].model}</p>
                <p
                    className="desc"
                    dangerouslySetInnerHTML={{
                        __html: portfolio[currenLanguage].team,
                    }}
                />
                <p className="desc">{portfolio[currenLanguage].date}</p>
            </div>
        </div>
    );
};

PortfolioItem.propTypes = {
    portfolio: PropTypes.object,
};

export default PortfolioItem;
