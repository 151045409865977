import React from "react";
import RetouchItem from "../../components/portfolio/retouch-item";
import PortfolioData from "../../data/retouch.json";
import useMasonry from "../../hooks/use-masonry";

const RetouchGalleryContainer = () => {
    const { categories } = useMasonry(PortfolioData, ".portfolio-list");
    const RetouchGallery = PortfolioData.filter(
        (portfolio) => portfolio.compare
    );
    return (
        <div className="portfolio-area portfolio-default-area">
            <div className="container-fluid">
                <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 portfolio-list mb-n30">
                    <div className="col"></div>
                    {PortfolioData &&
                        RetouchGallery.map((portfolio) => (
                            <div
                                key={portfolio.id}
                                className={`col masonry-grid mb-30`}
                            >
                                <RetouchItem data={portfolio} />
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default RetouchGalleryContainer;
