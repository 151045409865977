import PriceData from "../../../data/price-data.json";
import { useTranslation } from "react-i18next";

const PrivateSection = () => {
    const { t, i18n } = useTranslation();
    const currenLanguage = i18n.language;
    const Data = PriceData.private;
    return (
        <div className="post-details-body">
            <div className="content row">
                <div className="col-sm-7 col-lg-7 col-md-7" data-aos="fade-up">
                    <h2 className="title">{Data.title[currenLanguage]}</h2>
                    <p
                        className="text-block"
                        dangerouslySetInnerHTML={{
                            __html: Data.subTitle[currenLanguage],
                        }}
                    />
                    <div className="desc">
                        <ul className="row">
                            {Data.serviceList[currenLanguage] &&
                                Data.serviceList[currenLanguage].map(
                                    (single, key) => {
                                        return (
                                            <div
                                                className="col-md-6 col-sm-6 col-lg-6"
                                                key={key}
                                            >
                                                <li>
                                                    <i className="icon_stop"></i>
                                                    {single}
                                                </li>
                                            </div>
                                        );
                                    }
                                )}
                        </ul>
                    </div>
                    <p
                        className="text-block"
                        dangerouslySetInnerHTML={{
                            __html: Data.rateTitle[currenLanguage],
                        }}
                    />
                    <ul>
                        {Data.rateList[currenLanguage] &&
                            Data.rateList[currenLanguage].map((single, key) => {
                                return <li key={key}>{single}</li>;
                            })}
                    </ul>
                    <p
                        className="text-block"
                        dangerouslySetInnerHTML={{
                            __html: Data.readiness[currenLanguage],
                        }}
                    />
                    <div className="desc">
                        <ul className="row">
                            {Data.readiness.timeFrame[currenLanguage] &&
                                Data.readiness.timeFrame[currenLanguage].map(
                                    (single, key) => {
                                        return (
                                            <div
                                                className="col-md-12 col-sm-12 col-lg-12"
                                                key={key}
                                            >
                                                <li>
                                                    <i className="icon_stop"></i>
                                                    {single}
                                                </li>
                                            </div>
                                        );
                                    }
                                )}
                        </ul>
                    </div>
                    <p
                        className="text-block"
                        dangerouslySetInnerHTML={{
                            __html: Data.extraSupport[currenLanguage],
                        }}
                    />
                    <div className="desc">
                        <ul className="row">
                            {Data.extraSupport.list[currenLanguage] &&
                                Data.extraSupport.list[currenLanguage].map(
                                    (single, key) => {
                                        return (
                                            <div
                                                className="col-md-12 col-sm-12 col-lg-12"
                                                key={key}
                                            >
                                                <li>
                                                    <i className="icon_stop"></i>
                                                    {single}
                                                </li>
                                            </div>
                                        );
                                    }
                                )}
                        </ul>
                    </div>
                    {Data.text[currenLanguage] &&
                        Data.text[currenLanguage].map((value, key) => {
                            return (
                                <p
                                    key={key}
                                    dangerouslySetInnerHTML={{
                                        __html: value,
                                    }}
                                />
                            );
                        })}
                </div>
                <div className="col-sm-5 col-md-5 col-lg-5" data-aos="fade-up">
                    <div className="thumb mb-30">
                        <img
                            className="w-100"
                            src={`${process.env.PUBLIC_URL}/${Data.gallery}`}
                            alt="Backstage Viktoria Lipkovska"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrivateSection;
